import React, { useState, useEffect } from "react";
import fetch from "isomorphic-fetch";
import SEO from "../components/SEO";
import { StaticQuery, graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import { decodeEntities, isClient } from "../utils/helpers";
import "./search.scss";
import { navigate } from "@reach/router";

const ListItem = ({ item }) => {
  const { url, title, subtype } = item;
  const slug = url.replace(process.env.GATSBY_WP_ADDRESS, "");
  if (slug.includes("gatsby")) return null;
  return (
    <GatsbyLink to={slug} className="site-search__list__item">
      <span>{decodeEntities(title)}</span>
      {subtype && (
        <div className="site-search__list__item-description">
          {decodeEntities(subtype.replace("-", " "))}
        </div>
      )}
    </GatsbyLink>
  );
};

const SiteSearch = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterResults, setFilterResults] = useState([]);
  const [isError, setIsError] = useState(null);
  const [search, setSearch] = useState("");
  const [searchMessage, setSearchMessage] = useState(null);

  // If there is a search query already in the URL, then start the search
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("s");

    if (searchParam) {
      setSearch(searchParam);
    }
  }, []);

  useEffect(() => {
    if (isClient) {
      if (location.state?.searchQuery) {
        setSearch(location.state.searchQuery);
      }
      if (location.state?.results) {
        setFilterResults(location.state.results);
      }
    }
  }, [location]);

  useEffect(() => {
    getSearch(search);

    // Clean up the URL there is no search result
    if (search.length === 0) {
      navigate("/search/");
    }
  }, [search]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getSearch();
  };

  const handleSearchBarUpdate = (e) => {
    setSearch(e.target.value);
    navigate(`/search?s=${e.target.value}`, {
      state: {
        searchQuery: e.target.value,
      },
    });
  };

  const getSearch = () => {
    if (!search) return null;
    const productsUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/search/?search=${search}&per_page=100&subtype=page,case-studies,products`; //Removed pagintaion to keep it simple. Use page=1... if it needs to be added back in
    setIsLoading(true);
    fetch(productsUrl)
      .then(async (res) => {
        const total = res.headers.get("x-wp-total");
        const data = await res.json();
        // set states
        setIsLoading(false);
        setFilterResults(data);
        setSearchMessage(`${total} search results`);
      })
      .catch((error) => {
        console.log("error:", error);
        setIsLoading(false);
        setIsError(error);
      });
  };

  return (
    <>
      <SEO
        title={`${search ? `Search results for: ${search}` : "Search Results"}`}
        location={location}
      />
      <section className="site-search layout">
        <div className="site-search__wrapper">
          <form onSubmit={(event) => handleSearchSubmit(event)}>
            <label htmlFor="searchbar" className="site-search__label">
              Search pages, case studies and products:
            </label>
            <div className="site-search__container">
              <input
                onChange={(event) => handleSearchBarUpdate(event)}
                id="searchbar"
                type="text"
                name="search"
                value={search}
                autoComplete="off"
              />
              <button className="site-search__button" />
            </div>
          </form>

          {isLoading && (
            <div className="loading-spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}

          {!isLoading && isError && (
            <div className="site-search__error">
              <div>Error: Please try again later.</div>
            </div>
          )}

          {searchMessage && (
            <h2 className="large-heading site-search__heading">
              {searchMessage}
            </h2>
          )}

          {filterResults?.length > 0 ? (
            <div className="site-search__list">
              {filterResults?.map((page, index) => (
                <ListItem key={index} item={page} index={index} />
              ))}
            </div>
          ) : (
            <div className="space" />
          )}
        </div>
      </section>
    </>
  );
};

const SearchTemplate = (props) => (
  <StaticQuery
    query={graphql`
      query SearchPageSearchIndexQuery {
        wordpressWpSettings {
          title
          wordpressUrl
        }
      }
    `}
    render={(data) => <SiteSearch data={data} {...props} />}
  />
);

export default SearchTemplate;
